<template>
    <div id="principal" class="container-fluid m-0 p-0">
        <div v-if="telaPronta" class="d-flex flex-column bg-white m-0">
            <!-- Botões de filtro por Requisições(1) e Operações(2) -->
            <div class="btn-group sticky-top card-header-filter mx-0 p-2 shadow-sm w-100" role="group">
                <button 
                    :id="'botao-'+item.Id"
                    type="button" 
                    :class="['btn btn-outline-primary mt-0', { 'btn-block': isMobile,'active': filtro.opcaoFiltro == item.Id}]" 
                    v-for="item in listaTipoPesquisa" 
                    :key="item.id" 
                    @click="filtro.opcaoFiltro = item.Id">
                    {{ item.Descricao }}
                </button>
            </div>
            <div class="m-1 sticky-top card-header-filter"></div>
            <div id="idFiltros" class="row px-2">
                <p :class="['title nav-tabs col-12 mb-0', isMobile ? 'text-center' : 'text-left']">
                    <b class="fz-80"> Buscar por {{ descricaoTipoSolicitacao() }} </b>
                </p>
                <!-- Data Inicial -->
                <div class="form-group col-sm-12 col-md-6 my-0">
                    <label class="pb-0 mb-0">Data Inicial</label>
                    <div class="input-group input-group-sm date border">
                        <pikaday-vue-datepicker
                            class="form-control"
                            :class="[{ 'is-invalid': filtro.erros && filtro.erros.dataInicial }]"
                            :nome="'datePickerDataInicial'"
                            v-model="filtro.dataInicial"
                            botao="lmxta-filtro-btn-dataInicial"
                        ></pikaday-vue-datepicker>
                        <span class="input-group-append" id="lmxta-filtro-btn-dataInicial">
                            <i class="icon icon-lx-calendar input-group-text input-group-sm bg-transparent"></i>
                        </span>
                        <div v-if="filtro.erros && filtro.erros.dataInicial" class="invalid-feedback">
                            <span v-html="filtro.erros.dataInicial"></span>
                        </div>
                    </div>
                </div>
                <!-- Data Final -->
                <div class="form-group col-sm-12 col-md-6 my-0">
                    <label class="pb-0 mb-0">Data Final</label>
                    <div class="input-group input-group-sm date">
                        <pikaday-vue-datepicker
                            class="form-control"
                            :class="[{ 'is-invalid': filtro.erros && filtro.erros.dataFinal }]"
                            :nome="'datePickerDataFinal'"
                            v-model="filtro.dataFinal"
                            botao="lmxta-filtro-btn-dataFinal"
                        ></pikaday-vue-datepicker>
                        <span class="input-group-append" id="lmxta-filtro-btn-dataFinal">
                            <i class="icon icon-lx-calendar input-group-text input-group-sm bg-transparent"></i>
                        </span>
                        <div v-if="filtro.erros && filtro.erros.dataFinal" class="invalid-feedback">
                            <span v-html="filtro.erros.dataFinal"></span>
                        </div>
                    </div>
                </div>
                <!-- Filtro Status -->
                <div class="form-group col-12 col-sm-12 col-md-6 my-1">
                    <label class="pb-0 mb-0">Status</label>
                    <multiselect
                        v-model="filtro.status"
                        :options="listaStatusRequisicoes"
                        :multiple="true"
                        :close-on-select="false"
                        placeholder="Selecione..."
                        selectLabel="Selecionar"
                        deselectLabel="Remover"
                        selectedLabel="Selecionado"
                        label="Descricao"
                        track-by="Id"
                    >
                        <template slot="selection" slot-scope="select">
                            <span class="multiselect__single" v-if="select.values.length == 1 && !select.isOpen">
                                {{ select.values[0].Descricao }}
                            </span>
                            <span class="multiselect__single" v-if="select.values.length > 1 && !select.isOpen">
                                {{ select.values.length }}
                                selecionados
                            </span>
                        </template>
                        <span slot="noResult">Nenhum registro encontrado</span>
                        <span slot="noOptions">Selecione um Status</span>
                    </multiselect>
                </div>
                <!-- Filtro Tipo Requisição - (Disponível somente para Requisições(1) ) -->
                <div class="form-group col-12 col-sm-12 col-md-6 my-1" v-if="filtro.opcaoFiltro == TipoSolicitacao.REQUISICAO_WMS.value">
                    <label class="pb-0 mb-0">Tipo de Requisição</label>
                    <multiselect
                        v-model="filtro.tiposRequisicao"
                        :options="listaTiposRequisicao"
                        :multiple="true"
                        :close-on-select="false"
                        placeholder="Selecione..."
                        selectedLabel="Selecionado"
                        selectLabel="Selecionar"
                        deselectLabel="Remover"
                        label="Descricao"
                        track-by="IdWMSTipoRequisicao"
                    >
                        <template slot="selection" slot-scope="select">
                            <span class="multiselect__single" v-if="select.values.length == 1 && !select.isOpen">
                                {{ select.values[0].Descricao }}
                            </span>
                            <span class="multiselect__single" v-if="select.values.length > 1 && !select.isOpen">
                                {{ select.values.length }}
                                selecionados
                            </span>
                        </template>
                        <span slot="noResult">Nenhum registro encontrado</span>
                        <span slot="noOptions">Selecione um Tipo de Requisição</span>
                    </multiselect>
                </div>
                <!-- Filtro Responsável/Vendedor -->
                <div class="form-group col-12 col-sm-12 col-md-6 my-1" v-if="filtro.opcaoFiltro == TipoSolicitacao.REQUISICAO_WMS.value">
                    <label class="pb-0 mb-0">Responsável (Vendedor)</label>
                    <multiselect
                        v-model="filtro.vendedores"
                        :options="listaVendedores"
                        :multiple="true"
                        :close-on-select="false"
                        placeholder="Selecione..."
                        selectedLabel="Selecionado"
                        selectLabel="Selecionar"
                        deselectLabel="Remover"
                        label="Nome"
                        track-by="Id"
                    >
                        <template slot="selection" slot-scope="select">
                            <span class="multiselect__single" v-if="select.values.length == 1 && !select.isOpen">
                                {{ select.values[0] && select.values[0].Nome }}
                            </span>
                            <span class="multiselect__single" v-if="select.values.length > 1 && !select.isOpen">
                                {{ select.values.length }}
                                selecionados
                            </span>
                        </template>
                        <span slot="noResult">Nenhum registro encontrado</span>
                        <span slot="noOptions">Selecione um Responsável(Vendedor)</span>
                    </multiselect>
                </div>
                <!-- Filtro Etapa -->
                <div class="form-group col-12 col-sm-12 col-md-6 my-1">
                    <label class="pb-0 mb-0">Etapa</label>
                    <multiselect
                        v-model="filtro.etapasProcesso"
                        :options="listaEtapasProcesso"
                        :multiple="true"
                        :close-on-select="false"
                        placeholder="Selecione..."
                        selectedLabel="Selecionado"
                        selectLabel="Selecionar"
                        deselectLabel="Remover"
                        label="Descricao"
                        track-by="Id"
                    >
                        <template slot="selection" slot-scope="select">
                            <span class="multiselect__single" v-if="select.values.length == 1 && !select.isOpen">
                                {{ select.values[0].Descricao }}
                            </span>
                            <span class="multiselect__single" v-if="select.values.length > 1 && !select.isOpen">
                                {{ select.values.length }}
                                selecionados
                            </span>
                        </template>
                        <span slot="noResult">Nenhum registro encontrado</span>
                        <span slot="noOptions">Selecione uma Etapa</span>
                    </multiselect>
                </div>
                <!-- Tipo de Operação (Disponível somente para Operações(2) ) -->
                <div class="form-group col-12 col-sm-12 col-md-6 my-1" v-if="filtro.opcaoFiltro == TipoSolicitacao.MOVIMENTO_ENTRADA_SAIDA.value">
                    <label class="pb-0 mb-0">Tipo de Operação</label>
                    <multiselect
                        v-model="filtro.tipoOperacao"
                        :options="listaTiposOperacao"
                        :close-on-select="false"
                        placeholder="Selecione..."
                        selectedLabel="Selecionado"
                        selectLabel="Selecionar"
                        deselectLabel="Remover"
                        label="Descricao"
                        track-by="Id"
                    >
                        <template slot="selection" slot-scope="select">
                            <span class="multiselect__single" v-if="select.values.length == 1 && !select.isOpen">
                                {{ select.values[0].Descricao }}
                            </span>
                            <span class="multiselect__single" v-if="select.values.length > 1 && !select.isOpen">
                                {{ select.values.length }}
                                selecionados
                            </span>
                        </template>
                        <span slot="noResult">Nenhum registro encontrado</span>
                        <span slot="noOptions">Selecione um Tipo de Operação</span>
                    </multiselect>
                </div>

                <!-- Filtro Cliente -->
                <div class="form-group col-12 col-sm-12 col-md-6 my-1" @keydown.enter.prevent v-if="filtro.opcaoFiltro == TipoSolicitacao.MOVIMENTO_ENTRADA_SAIDA.value">
                    <div class="input-group">
                        <label class="pb-0 mb-0">Cliente ou Fornecedor</label>

                        <div class="input-group">
                            <input
                                id="lmxta-buscaavancada-input-nome"
                                class="form-control form-control-sm"
                                type="text"
                                @click="abrirModalPesquisaCliente"
                                data-toggle="modal"
                                data-target="#ModalPesquisaCliente"
                                v-model="filtro.clienteFornecedor.Nome"
                            />
                            <div
                                id="lmxta-buscaavancada-btn-remover"
                                class="input-group-addon d-flex align-items-center border justify-content-center"
                                style="background-color: #eee; width: 35px"
                                @click="removerClienteInput"
                            >
                                <i class="fa fa-times-circle fa-lg" aria-hidden="true" style="color: #555 !important"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Tipo de Operação (Disponível somente para Operações(2) ) -->
                <div class="form-group col-12 col-sm-12 col-md-6 my-1" v-if="filtro.opcaoFiltro == TipoSolicitacao.MOVIMENTO_ENTRADA_SAIDA.value">
                    <label class="pb-0 mb-0">Natureza de Operação</label>
                    <multiselect
                        v-model="filtro.naturezasOperacao"
                        :options="listaNaturezaOperacoes"
                        :multiple="true"
                        :close-on-select="false"
                        placeholder="Selecione..."
                        selectedLabel="Selecionado"
                        selectLabel="Selecionar"
                        deselectLabel="Remover"
                        label="Descricao"
                        track-by="Id"
                    >
                        <template slot="selection" slot-scope="select">
                            <span class="multiselect__single" v-if="select.values.length == 1 && !select.isOpen">
                                {{ select.values[0].Descricao }}
                            </span>
                            <span class="multiselect__single" v-if="select.values.length > 1 && !select.isOpen">
                                {{ select.values.length }}
                                selecionados
                            </span>
                        </template>
                        <span slot="noResult">Nenhum registro encontrado</span>
                        <span slot="noOptions">Selecione uma Natureza de Operação</span>
                    </multiselect>
                </div>

                <div class="form-group col-12 col-sm-12 col-md-6 my-1">  
                    <div class="input-group">
                        <label class="pb-0 mb-0">Produto</label>

                        <div class="input-group">
                            <input
                                id="lmxta-buscaproduto-input-nome"
                                class="form-control form-control-sm"
                                type="text"
                                v-model="filtro.produto"
                            />
                            <div class="input-group-append">
                                <button class="btn btn-primary btn-sm float-right" 
                                    @click="abrirModalPesquisaProduto"
                                    data-toggle="modal"
                                    data-target="#ModalPesquisaProduto">
                                    
                                    <span class="text-center">
                                        <i class="icon icon-lx-search"></i>
                                    </span>
                                </button>
                            </div>
                            <div
                                id="lmxta-buscaproduto-btn-remover"
                                class="input-group-addon d-flex align-items-center border justify-content-center"
                                style="background-color: #eee; width: 35px"
                                @click="removerProdutoInput"
                            >
                                <i class="fa fa-times-circle fa-lg" aria-hidden="true" style="color: #555 !important"></i>
                            </div>
                        </div>
                    </div>                 
                </div>                     

                <!--End filtros-->
            </div>
            <!--End filtros-->
            <!-- Botão Voltar -->
            <div class="fixed-bottom bg-white mb-0">
                <div class="btn-group w-100 flex justify-content-end">
                    <button :class="['btn btn-default ', isMobile ? 'btn-block mx-0 my-1' : 'm-1']" @click="$router.push({ name: 'home' })">
                        <i class="fas fa-arrow-left fa-lg pl-2"></i>
                        Voltar
                    </button>
                    <button :class="['btn btn-secondary', isMobile ? 'btn-block mx-0 my-1' : 'm-1']" @click="onFiltrar()">
                        Filtrar
                        <i class="fas fa-search fa-lg pl-2"></i>
                    </button>
                </div>
            </div>

            <!-- Grid para tratar registros não encontrados ou filtro não aplicado -->
            <div :class="['text-center card-info-search', isMobile ? 'm-2' : 'm-5']">
                <div :class="{ 'd-flex align-items-center justify-content-center mt-2': isMobile }">
                    <div>
                        <span class="text-center">
                            <img :src="iconeSearch" />
                        </span>
                    </div>
                    <div>
                        <span :class="['text-uppercase text-muted', isMobile ? 'fz-70' : 'fz-95']"> Informe os filtros desejados para iniciar a consulta </span>
                    </div>
                </div>
            </div>
        </div>

        <base-modal
            v-show="modais.cliente.isModalVisible"
            @close="fecharModalPesquisaClientes()"
            :classesAdicionaisModal="'modal-pesquisa rounded overflow-x-hidden w-60 w-sm-100 position-relative'"
            :classesAdicionaisBackDrop="'align-items-start'"
            :classesAdicionaisHeader="'d-none'"
            :classesAdicionaisFooter="'d-none'"
            :classesAdicionaisBody="'py-2 overflow-x-hidden'"
            id="modalPesquisaCliente"
        >
            <div slot="body">
                <selecaoClienteInput
                    :tipoCrmPesquisa="'A'"
                    :permiteCadastro="false"
                    ref="SelecaoClienteInput"
                    v-model="filtro.clienteFornecedor"
                    @close="modais.cliente.isModalVisible = false"
                />
            </div>
        </base-modal>

        <base-modal
            v-show="modais.produto.isModalVisible"
            @close="fecharModalPesquisaProdutos()"
            :classesAdicionaisModal="'modal-pesquisa rounded overflow-x-hidden w-60 w-sm-100 position-relative'"
            :classesAdicionaisBackDrop="'align-items-start'"
            :classesAdicionaisHeader="'d-none'"
            :classesAdicionaisFooter="'d-none'"
            :classesAdicionaisBody="'py-2 overflow-x-hidden'"
            id="modalPesquisaProduto"
        >
            <div slot="body">
                <selecaoProdutoInput
                    ref="SelecaoProdutoInput"
                    v-model="filtro.produto"
                    @close="modais.produto.isModalVisible = false"
                />
            </div>
        </base-modal>

    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import loading from '@/common/loading';

import baseModal from '@/components/base-modal.vue';
import selecaoClienteInput from '@/components/selecao-cliente-input.vue';
import selecaoProdutoInput from '@/components/selecao-produto-input.vue';
import pikadayVueDatepicker from '@/components/pikaday-vue-datepicker';
import { Multiselect } from 'vue-multiselect';

//Enumns
import TipoSolicitacao from '@/domain/tipo-solicitacao';

//URLs Icones/Imagens
const imgIconSearch = require('@/assets/Images/linx-icon-ilustrativo/search.png');

export default {
    name: 'filtros-requisicoes',
    components: {
        pikadayVueDatepicker,
        Multiselect,
        selecaoClienteInput,
        selecaoProdutoInput,
        baseModal
    },
    data() {
        return {
            TipoSolicitacao,
            tipoMensagem: {
                Sucesso: 1,
                Erro: 2,
                Aviso: 3
            },
            telaPronta: false,
            iconeSearch: imgIconSearch,
            filtro: {
                erros: {},
                opcaoFiltro: 1,

                tipoPesquisa: null,
                tipoOperacao: null,
                tiposRequisicao: null,
                status: null,
                etapasProcesso: null,
                etapasOperacao: null,
                vendedores: null,
                dataInicial: null,
                dataFinal: null,
                naturezasOperacao: null,
                clienteFornecedor: { Nome: null, Codigo: null },
                produto: null
            },
            modais: {
                cliente: {
                    selecionado: null,
                    isModalVisible: false
                },
                produto: {
                    selecionado: null,
                    isModalVisible: false
                }
            }
        };
    },
    computed: {
        ...mapGetters({
            isMobile: 'appData/ehWMSMobile',
            listaVendedores: 'requisicoesWMS/vendedores',
            listaTipoPesquisa: 'requisicoesWMS/tipoSolicitacao',
            listaStatus: 'requisicoesWMS/statusRequisicao',
            listaTiposRequisicao: 'requisicoesWMS/tiposRequisicao',
            listaEtapasProcessoRequisicao: 'requisicoesWMS/etapasProcesso',
            listaNaturezaOperacoes: 'requisicoesWMS/naturezasOperacao',
            listaTiposOperacao: 'requisicoesWMS/tiposOperacao',
            listaMovimentacoesRequisicao: 'requisicoesWMS/requisicoes',
            listaFiltros: 'requisicoesWMS/filtrosRequisicoes'
        }),
        listaEtapasProcesso() {
            return this.filtro.opcaoFiltro == TipoSolicitacao.REQUISICAO_WMS.value
                ? this.listaEtapasProcessoRequisicao.filter(function (x) {
                      return x.Id >= 1 && x.Id <= 3;
                  })
                : this.listaEtapasProcessoRequisicao.filter(function (x) {
                      return x.Id >= 1 && x.Id <= 2;
                  });
        },
        listaStatusRequisicoes() {
            return this.listaStatus.filter(function (x) {
                return x.Id >= 1 && x.Id <= 3;
            });
        }
    },
    mounted() {
        this.carregarDadosIniciais();
        this._inicializarFiltroData();
    },

    methods: {
        async carregarDadosIniciais() {
            loading.exibir();
            this.filtro = this.listaFiltros;
            await this.$store
                .dispatch('requisicoesWMS/obterDadosIniciaisPainelMovimentacoes')
                .then(() => {
                    this.telaPronta = true;
                })
                .catch((err) => {
                    this.$helper.globalErrorHandler(err, 'Não foi possível obter os dados necessários, tente novamente');
                    loading.ocultar();
                    this.$router.push({
                        name: 'home'
                    });
                })
                .finally(() => loading.ocultar());
        },
        _atualizarTituloPagina() {
            let titlePage = this.filtro.opcaoFiltro == TipoSolicitacao.REQUISICAO_WMS.value ? 'Listagem das Requisições' : 'Listagem das Operações';
            this.$store.dispatch('uiState/updateNavbar', titlePage);
        },
        _inicializarFiltroData: function () {
            var self = this;
            if (!self.filtro?.dataInicial || !self.filtro?.dataFinal){
                self.filtro.dataInicial = this.$moment()
                    .subtract({
                        month: 1
                    })
                    .format('DD/MM/YYYY');
                self.filtro.dataFinal = this.$moment().format('DD/MM/YYYY');
            }
        },

        onFiltrar: function () {
            var self = this;
            if (!self._validarFiltros()) {
                return;
            } else {
                this.$store.dispatch('requisicoesWMS/atualizarFiltrosRequisicoes', self.filtro);
                this.$router.push({ name: 'lista-requisicoes' });
            }
        },

        _descricaoItens: function (lista, opcoes) {
            opcoes = opcoes || {};
            if (!opcoes.funcaoDisplay)
                opcoes.funcaoDisplay = function (x) {
                    return x.Descricao;
                };
            if (!opcoes.textoTodos) opcoes.textoTodos = 'Todos';
            if (!lista || lista.length == 0) return opcoes.textoTodos;
            return lista.map(opcoes.funcaoDisplay).join(', ');
        },

        _validarFiltros: function () {
            var self = this;
            self.filtro.erros = {};
            self._validarDataInicial();
            self._validarDataFinal();
            self._validarDataFinalMenorQueInicial();
            return Object.keys(self.filtro.erros).length === 0;
        },

        _validarDataFinalMenorQueInicial: function () {
            var self = this;
            if (self.filtro.dataInicial && self.filtro.dataFinal) {
                if (this.$moment(self.filtro.dataInicial, 'DD/MM/YYYY') > this.$moment(self.filtro.dataFinal, 'DD/MM/YYYY'))
                    self.filtro.erros.dataInicial = 'A data final não pode ser anterior a data inicial';
            }
        },

        _validarDataInicial: function () {
            var self = this;
            if (!self.filtro.dataInicial || !this.$moment(self.filtro.dataInicial, 'DD/MM/YYYY').isValid()) {
                self.filtro.erros.dataInicial = 'A data inicial precisa ser informada';
            }
        },

        _validarDataFinal: function () {
            var self = this;
            if (!self.filtro.dataFinal || !this.$moment(self.filtro.dataFinal, 'DD/MM/YYYY').isValid()) {
                self.filtro.erros.dataFinal = 'A data final precisa ser informada';
            }
        },

        //Modais Input Cliente
        abrirModalPesquisaCliente: function () {
            this.modais.cliente.isModalVisible = true;
            setTimeout(() => {
                document.querySelector('#lmxta-selecaocliente-input-pesquisa').focus();
            }, 1000);
        },
        fecharModalPesquisaClientes() {
            this.modais.cliente.isModalVisible = false;
        },
        removerClienteInput() {
            this.filtro.clienteFornecedor = {
                Codigo: null,
                Nome: null
            };
        },

        //Modal Input Produto
        abrirModalPesquisaProduto: function () {
            this.modais.produto.isModalVisible = true;
            setTimeout(() => {
                document.querySelector('#lmxta-selecaoproduto-input-pesquisa').focus();
            }, 1000);
        },
        fecharModalPesquisaProdutos() {
            this.modais.produto.isModalVisible = false;
        },
        removerProdutoInput() {
            this.filtro.produto = null;
        },

        //Métodos auxiliares
        _mapOrEmpty: function (array, field) {
            if (array) return this.$_.map(array, field);
            return [];
        },
        descricaoTipoSolicitacao: function () {
            return this.listaTipoPesquisa.length && this.listaTipoPesquisa.filter((filtro) => filtro.Id == this.filtro.opcaoFiltro)[0]?.Descricao;
        },
    }
};
</script>

<style scoped>
.btn-block-mw {
    max-width: 120px;
}
.card-data {
    /* margin-top: 65px; */
    margin-top: 0px;
}
.card-header-filter {
    /* margin-top: 58px; */
    margin-top: 0px;
    background-color: #f9f9f9;
}
</style>
