<template>
    <div class="selecao-produto-input overflow-x-hidden" @keydown.esc="fecharModal()">
        <div class="d-flex fl<ex-row align-items-baseline col-12 h-2 p-0">
            <span v-show="!isLoading">
                <i class="fa fa-search busca-modal-icon" />
            </span>
            <span v-show="isLoading">
                <i class="fas fa-spinner fa-spin busca-modal-icon" />
            </span>

            <input
                type="text"
                class="input-pesquisa pl-2 w-100"
                id="lmxta-selecaoproduto-input-pesquisa"
                :value="search"
                @input="search = $event.target.value"
                autocomplete="off"
                placeholder="Pesquisar..."               
                ref="item"
            />

            <button type="button" id="lmxta-selecaoproduto-btn-fechar" class="close" data-dismiss="modal" aria-label="Close" @click="fecharModal()">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <hr class="m-0 p-0 w-100"/>
        <a
            href="javascript:void(0)"
            class="link-busca lmxta-selecaoproduto-btn-selecionar"
            v-for="(item, index) in results"
            :key="index"
            v-bind:if="results.length"
            @click="selecionarProduto(item)"
        >
            <div class="row mt-1 tela opcao-click">
                <div class="col-12">
                    <i class="fa fa-cube ml-2" aria-hidden="true"></i>
                    <span class="nome-tela ml-2">
                        {{ item.Descricao }} <small>(Ref: {{ item.Referencia }} | Cód: {{ item.Codigo }})</small>
                    </span>
                </div>
            </div>
        </a>
        <a href="javascript:void(0)" class="link-busca" v-if="results.length == 0">
            <div class="row mt-1 tela">
                <div class="col-12">
                    <span class="nome-tela ml-2 font-italic"> pelo código, referência ou descrição. </span>
                </div>
            </div>
        </a>
        
    </div>
</template>

<script>
import swal from '@/common/alerts';
import { mapGetters } from 'vuex';

export default {
    name: 'SelecaoProdutoInput',
    data() {
        return {
            isOpen: false,
            notFound: false,
            isLoading: false,
            results: [],
            search: '',
            selectedResult: null,
            apiService: null,
            resultsLimit: 30
        };
    },
    mounted() {
        document.addEventListener('click', this.handleClickOutside);

        window.setTimeout(function () {
            document.querySelector('#lmxta-selecaoproduto-input-pesquisa').focus();
        }, 200);        
    },
    computed: {
        ...mapGetters('listaProduto', {
            model: 'dadosProdutos',
        }),

        hasExtraResults() {
            return this.results && this.results.length >= this.resultsLimit;
        },
    },
    watch: {
        search() {
            this.filterResults();
        },
        selectedResult(val) {
            if (val) this.selecionarProduto(val);
        }
    },
    methods: {
        selecionarProduto(item) {
            this.$emit('input', item.Codigo);
            this.fecharModal();
        },

        fecharModal() {
            this.search = '';
            this.$emit('close');
        },

        filterResults() {
            var searchCriteria = this.search.toLowerCase();
            this.isLoading = true;
            var self = this;

            setTimeout(() => {
                if (searchCriteria != this.search.toLowerCase()) return;

                if (!searchCriteria) {
                    this.results = [];
                    this.selectedResult = null;
                    this.isLoading = false;
                    this.notFound = false;
                    return;
                }
                var query = {
                    NomeRefProd: searchCriteria,
                    LimiteRegistros: 20,
                    ComSaldoEstoque: false
                }

                this.$store
                    .dispatch('listaProduto/Autocompletar', query)
                    .then((response) => {
                        let resultado = response.data;
                        self.results = resultado;
                        self.isLoading = false;
                        self.notFound = !resultado || resultado.length == 0;
                    })
                    .catch((error) => {
                        swal.exibirMensagemErro('Ocorreu um problema.' + error);
                        self.isLoading = false;
                        return;
                    });
            }, 700);
        },
       
        setResult(result) {
            this.isOpen = false;
            this.selectedResult = result;

            if (!result) this.search = '';
        },

        clearInput() {
            this.selectedResult = null;
            this.search = '';
            this.$refs.produtoInput.focus();
        },

        handleClickOutside(evt) {
            if (!this.$el.contains(evt.target)) {
                this.isOpen = false;
            }
        },

    }
};
</script>

<style scoped>
.fz-95 {
    font-size: 95% !important;
}

.fz-90 {
    font-size: 90% !important;
}

.fz-85 {
    font-size: 85% !important;
}

.img-size-4 {
    width: 5%;
}

.img-size-2 {
    width: 3%;
}

.lx-1x3x {
    font-size: 1.3rem !important;
}

.busca-modal-icon {
    font-size: 20px;
}

.modal-pesquisa {
    overflow-y: auto;
    overflow-x: hidden !important;
}

.modal-pesquisa .modal-dialog {
    max-width: 100%;
}

.modal-pesquisa .input-pesquisa,
.modal-pesquisa .selecao-produto-input input {
    border: 0 !important;
    background: transparent;
    font-size: 20px;
    height: 40px;
    border: 0px;
    width: 60%;
    padding-bottom: 0px;
}

.modal-pesquisa .input-pesquisa:focus {
    outline: none;
}

.modal-pesquisa .input-pesquisa::-ms-clear {
    display: none;
}

.modal-pesquisa .input-pesquisa:-ms-input-placeholder {
    color: black;
}

.modal-pesquisa .busca-modal-icon {
    font-size: 20px;
}

.modal-pesquisa .descricao-tela {
    font-style: italic;
}

.modal-pesquisa .nome-tela {
    font-weight: 500;
    font-size: 15px;
}

.modal-pesquisa .modal-footer {
    background: #ebebeb;
    -webkit-border-radius: 0px !important;
    -moz-border-radius: 0px !important;
    border-bottom-left-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
    height: 33px;
}

.modal-pesquisa .esc-icon {
    width: 30px;
    height: 15px;
    font-size: 9px;
    background: #333;
    color: white;
    text-align: center;
    vertical-align: middle;
    line-height: 15px;
    display: inline-block;
}

.modal-pesquisa .fa-level-down {
    font-weight: 700;
}

.modal-pesquisa .modal-body {
    overflow-y: auto;
    overflow-x: hidden;
}

.modal-pesquisa .link-busca {
    text-decoration: none;
    color: #333;
}

.modal-pesquisa .pai:not(:first-child) {
    margin-top: 20px;
}

.selecao-produto-input {
    position: relative;
}

.link-busca {
    text-decoration: none;
    color: #333;
}

.selecao-produto-input-results {
    padding: 0;
    margin: 0;
    border: 1px solid #dee2e6;
    min-width: 300px;
    overflow-x: hidden;
    position: absolute;
    top: 31px;
    left: 0px;
    z-index: 1000;
    max-height: 450px;
    width: 100%;
    background: #fff;
    overflow: hidden;
}

.selecao-produto-input-results-scroll {
    overflow: auto;
}

.selecao-produto-input-results .loading {
    padding-top: 20px;
    text-align: center;
}

.selecao-produto-input-results .help {
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
}

.selecao-produto-input-results .external-link {
    padding: 3px;
    border-top: 1px solid #dee2e6;
    text-align: right;
}

.selecao-produto-input-result {
    list-style: none;
    text-align: left;
    padding: 4px 2px;
    cursor: pointer;
    font-size: 90%;
    overflow-x: hidden;
}

.selecao-produto-input-result.is-active,
.selecao-produto-input-result:hover {
    background-color: #ffb200;
    color: white;
}

.lmx-icon-gradient {
    color: #5b2e90;
    display: block;
    background: -webkit-linear-gradient(#2c004b, #5b2e90);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
</style>
