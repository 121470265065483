<template>
    <div>
        <div class="card-panel" style="padding: 0 0 0 0 !important">
            <div class="form-row">
                <div class="col-12">
                    <div class="row">
                        <p :class="{ 'title nav-tabs': true, 'col-8': estaEmModoAlteracao, 'col-12': !estaEmModoAlteracao }">
                            <b>Identificação</b>
                        </p>
                        <p class="title nav-tabs col-4 text-right" v-show="estaEmModoAlteracao">#{{ model.Id }}</p>
                        <div class="form-group col-12 col-sm-12 col-md-4 col-lg-4">
                            <label class="input-required">CPF / CNPJ</label>
                            <the-mask
                                id="lmxta-cadastropessoa-input-documento"
                                ref="txt_documento"
                                :mask="['###.###.###-##', '##.###.###/####-##']"
                                :type="'tel'"
                                class="form-control form-control-sm"
                                v-model="model.Documento"
                                @blur.native="onBlur_txtDocumento"
                            />
                        </div>
                        <div :class="{ 'form-group col-12 col-sm-12 col-md-5 col-lg-5': ehPessoaJuridica(), 'form-group col-12 col-sm-12 col-md-8 col-lg-8': !ehPessoaJuridica() }">
                            <label class="input-required">{{ labelCampoNome }}</label>
                            <input id="lmxta-cadastropessoa-input-nome" type="text" class="form-control form-control-sm" v-model="model.Nome" maxlength="60" />
                        </div>
                        <div class="form-group col-12 col-sm-12 col-md-3 col-lg-3" v-show="ehPessoaJuridica()">
                            <label class="input-required">Inscrição Estadual</label>
                            <input id="lmxta-cadastropessoa-input-inscricao" type="text" class="form-control form-control-sm" v-model="model.InscricaoEstadual" maxlength="20" />
                        </div>
                    </div>
                    <div class="row">
                        <p class="title nav-tabs col-12">
                            <b>Endereço</b>
                        </p>
                        <div class="form-group col-12 col-sm-3 col-md-3">
                            <label class="input-required">CEP</label>
                            <the-mask
                                id="lmxta-cadastropessoa-input-cep"
                                :mask="['#####-###']"
                                :type="'tel'"
                                class="form-control form-control-sm"
                                v-model="model.Endereco.CEP"
                                @blur.native="onBlur_txtCep"
                            />
                        </div>
                        <div class="form-group col-12 col-sm-9 col-md-9">
                            <label class="input-required">Logradouro</label>
                            <input id="lmxta-cadastropessoa-input-logradouro" type="text" class="form-control form-control-sm" v-model="model.Endereco.Logradouro" maxlength="250" />
                        </div>
                        <div class="form-group col-12 col-sm-5 col-md-5">
                            <label class="input-required">Número</label>
                            <the-mask
                                id="lmxta-cadastropessoa-input-numero"
                                :mask="['#############']"
                                :type="'text'"
                                ref="txt_numero_endereco"
                                class="form-control form-control-sm"
                                v-model="model.Endereco.Numero"
                                maxlength="20"
                            />
                        </div>
                        <div class="form-group col-12 col-sm-7 col-md-7">
                            <label>Complemento</label>
                            <input id="lmxta-cadastropessoa-input-complemento" type="text" class="form-control form-control-sm" v-model="model.Endereco.Complemento" maxlength="60" />
                        </div>
                        <div class="form-group col-12 col-sm-5 col-md-5">
                            <label class="input-required">Bairro</label>
                            <input id="lmxta-cadastropessoa-input-bairro" type="text" class="form-control form-control-sm" v-model="model.Endereco.Bairro" maxlength="60" />
                        </div>
                        <div class="form-group col-12 col-sm-5 col-md-5">
                            <label class="input-required">Cidade</label>
                            <input
                                id="lmxta-cadastropessoa-input-cidade"
                                type="text"
                                class="form-control form-control-sm"
                                v-model="model.Endereco.Cidade"
                                :disabled="sugeriuEnderecoPorCEP"
                                maxlength="40"
                            />
                        </div>
                        <div class="form-group col-12 col-sm-2 col-md-2">
                            <label class="input-required">UF</label>
                            <select id="lmxta-cadastropessoa-select-uf" class="form-control form-control-sm" v-model="model.Endereco.UF" :disabled="sugeriuEnderecoPorCEP">
                                <option value="AC">AC</option>
                                <option value="AL">AL</option>
                                <option value="AM">AM</option>
                                <option value="AP">AP</option>
                                <option value="BA">BA</option>
                                <option value="CE">CE</option>
                                <option value="DF">DF</option>
                                <option value="ES">ES</option>
                                <option value="GO">GO</option>
                                <option value="MA">MA</option>
                                <option value="MG">MG</option>
                                <option value="MS">MS</option>
                                <option value="MT">MT</option>
                                <option value="PA">PA</option>
                                <option value="PB">PB</option>
                                <option value="PE">PE</option>
                                <option value="PI">PI</option>
                                <option value="PR">PR</option>
                                <option value="RJ">RJ</option>
                                <option value="RN">RN</option>
                                <option value="RO">RO</option>
                                <option value="RR">RR</option>
                                <option value="RS">RS</option>
                                <option value="SC">SC</option>
                                <option value="SE">SE</option>
                                <option value="SP">SP</option>
                                <option value="TO">TO</option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <p class="title nav-tabs col-12">
                            <b>Contato</b>
                        </p>
                        <div class="form-group col-12 col-sm-6 col-md-4">
                            <label>Telefone</label>
                            <the-mask id="lmxta-cadastropessoa-input-telefone" type="tel" :mask="['(##) ####-####', '(##) #####-####']" class="form-control form-control-sm" v-model="model.Telefone" />
                        </div>
                        <div class="form-group col-12 col-sm-6 col-md-4">
                            <label>Celular</label>
                            <the-mask id="lmxta-cadastropessoa-input-celular" type="tel" :mask="['(##) ####-####', '(##) #####-####']" class="form-control form-control-sm" v-model="model.Celular" />
                        </div>
                        <div class="form-group col-12 col-sm-12 col-md-4">
                            <label>Email</label>
                            <input id="lmxta-cadastropessoa-input-email" type="email" class="form-control form-control-sm" v-model="model.Email" maxlength="50" />
                        </div>
                    </div>
                    <div class="row" v-show="ehPessoaFisica()">
                        <p class="title nav-tabs col-12">
                            <b>Dados pessoais</b>
                        </p>
                        <div class="form-group col-12 col-sm-6 col-md-6">
                            <label>Data nascimento</label>
                            <div class="input-group input-group-sm date">
                                <!-- <input class="form-control" type="date" v-model="model.DataNascimento" /> -->
                                <pikaday-vue-datepicker
                                    class="form-control"
                                    :nome="'datePickerDataNascimento' + GerarNumeroDinamico()"
                                    v-model="model.DataNascimento"
                                    botao="lmxta-cadastropessoa-btn-datanascimento"
                                ></pikaday-vue-datepicker>
                                <span class="input-group-append" id="lmxta-cadastropessoa-btn-datanascimento">
                                    <i class="icon icon-lx-calendar input-group-text input-group-sm bg-transparent"></i>
                                </span>
                            </div>
                        </div>
                        <div class="form-group col-12 col-sm-6 col-md-6">
                            <label>Sexo</label>
                            <select id="lmxta-cadastropessoa-select-sexo" class="form-control form-control-sm" v-model="model.Sexo">
                                <option value>Não informado</option>
                                <option value="M">Masculino</option>
                                <option value="F">Feminino</option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <p class="title nav-tabs col-12">
                            <b>Política de tratamento dos dados</b>
                        </p>
                        <div class="col-12">
                            <div class="alert alert-secondary">
                                <h4 class="alert-heading"></h4>
                                <p>{{ mensagemPoliticaTratamentoDados }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="mb-2 col-12 d-flex flex-row justify-content-between">
                <input id="lmxta-cadastropessoa-btn-sair" type="button" value="Voltar" class="btn btn-default" @click="btnSair_Click" />
                <input id="lmxta-cadastropessoa-btn-salvar" type="button" value="Salvar" class="btn btn-primary" @click="btnSalvar_Click" />
            </div>
        </div>
    </div>
</template>

<script>
import loading from '@/common/loading';
import { mapGetters } from 'vuex';
import pikadayVueDatepicker from '@/components/pikaday-vue-datepicker';
import { TheMask } from 'vue-the-mask';

export default {
    name: 'CadastroPessoa',
    components: {
        pikadayVueDatepicker,
        TheMask
    },
    data() {
        return {};
    },
    props: {
        tipoCrm: String,
        idParaAlteracao: Number,
        documentoParaAlteracao: String,
        limparCampos: {
            type: Boolean,
            default: true
        }
    },

    computed: {
        ...mapGetters('cliente', {
            model: 'dadosCadastro',
            estaEmModoAlteracao: 'estaEmModoAlteracao',
            ehPessoaJuridica: 'ehPessoaJuridica',
            ehPessoaFisica: 'ehPessoaFisica',
            labelCampoNome: 'labelCampoNome',
            GerarNumeroDinamico: 'GerarNumeroDinamico',
            sugeriuEnderecoPorCEP: 'sugeriuEnderecoPorCEP'
        }),
        ...mapGetters({
            isMobile: 'appData/ehVendaFacilMobile',
            mensagemPoliticaTratamentoDados: 'appData/mensagemPoliticaTratamentoDados'
        })
    },

    methods: {
        onBlur_txtDocumento() {
            loading.exibir();
            this.$store
                .dispatch('cliente/onBlur_txtDocumento')
                .then(() => loading.ocultar())
                .catch(() => loading.ocultar());
        },

        onBlur_txtCep() {
            loading.exibir();
            this.$store
                .dispatch('cliente/onBlur_txtCep')
                .then(() => loading.ocultar())
                .catch(() => loading.ocultar());
        },

        btnSair_Click() {
            if (this.limparCampos) this.$store.dispatch('cliente/resetarInformacoesPessoa');

            this.ultimoCepPesquisado = '';
            this.ultimoDocumentoPesquisado = '';

            this.$emit('input', { salvou: false, dados: null });
            //router.push({ name: "Atendimento" });
        },

        btnSalvar_Click() {
            loading.exibir();
            this.$store
                .dispatch('cliente/validarInformacoesParaSalvar')
                .then((validacaoOk) => {
                    if (!validacaoOk) {
                        loading.ocultar();
                        return false;
                    }

                    this.$store
                        .dispatch('cliente/salvar')
                        .then(() => {
                            this.$emit('input', {
                                salvou: true,
                                dados: this.model
                            });
                            loading.ocultar();
                        })
                        .catch(() => {
                            loading.ocultar();
                            return;
                        });
                })
                .catch(() => {
                    loading.ocultar();
                });
        }
    },

    mounted() {
        loading.exibir();

        let urlDispatch = null;
        let paramRequest = null;

        setTimeout(() => {
            if (this.idParaAlteracao) {
                urlDispatch = 'cliente/buscarPessoaPorId';
                paramRequest = this.idParaAlteracao;
            } else if (this.documentoParaAlteracao) {
                urlDispatch = 'cliente/buscarPessoaPorDocumento';
                paramRequest = this.documentoParaAlteracao;
            }

            if (urlDispatch)
                this.$store
                    .dispatch(urlDispatch, paramRequest)
                    .then(() => {
                        loading.ocultar();
                    })
                    .catch(() => {
                        loading.ocultar();
                    });
            else loading.ocultar();
        }, 500);
    },

    destroyed() {
        this.$store.dispatch('cliente/sair');
    }
};
</script>
